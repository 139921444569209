import React, {useState, useEffect, useReducer} from 'react'
import { useParams}  from 'react-router-dom'
import NumericInput from "react-numeric-input";
import {useSolv, useTenant} from "../components/SolvProvider"
import {useIntlEx} from "../components/IntlUtils"
import {FormHeader, FormBody, MainContainer, FormGroup} from "../components/FormComps.js"
import {validateField, showError, clearAllErrors, InfoBlock, InfoText} from '../components/ValidationUtils.js';
import {simpleReducer} from "../components/ReactUtils";
import MainMenu from "../MainMenu"
import SystemTabs from "./SystemTabs"
import getLogger from "../components/Logging.js"
import '../App.css'
import {default as Select} from "react-select";
import AsyncSelect from "react-select/async";
import {AsyncPaginate} from "react-select-async-paginate";

const log = getLogger("Location")

export default function Location(props) {

  const {api, session: {user}, refreshSignin, setFatal, setBusy} = useSolv()
  const {intl } = useIntlEx()

  const statusOpts = [
    {
      value: "ACTIVE",
      label: intl.msg("status_active")
    },
    {
      value: "INACTIVE",
      label: intl.msg("status_inactive")
    },
  ]

  function getStatusOpts() {
    return (statusOpts)
  }

  function getStatusOpt(status) {
    return (statusOpts.find(i => i.value === status))
  }

  const params = useParams()

  const {tenant} = useTenant(params.tenantId)

  const [locationId, setLocationId] = useState(null)
  const [isAdding, setAdding] = useState(null)

  const [touched, setTouched] = useState(false)
  const [alert, setAlert] = useState(null)

  const [location, updateLocation] = useReducer(simpleReducer, {})
  const [country, setCountry] = useState(null)
  const [status, setStatus] = useState(getStatusOpt("ACTIVE"))

  useEffect(() => {
    if (params.locationId) {
      if (params.locationId.trim().toLowerCase() === "_new") {
        setLocationId(null)
        setCountry({value: `??`, label: `Unknown`})
        setAdding(true)
      }
      else {
        setLocationId(params.locationId)
        setCountry({value: `??`, label: `Unknown`})
        setAdding(false)
      }
    }
  }, [])

  useEffect(() => {

    log.debug("useEffect: invoked")

    if (locationId) {
      setBusy(intl.msg("loading"))
      api.getLocation(locationId)
        .then(({data}) => {
          log.debug("getLocation: data=", data)
          updateLocation(data)
          setCountry({value: data.countryCode, label: `${data.countryName} (${data.countryCode})`})
          setStatus(getStatusOpt(data.status))
        })
        .catch((error) => {
          log.error(error)
          setFatal(error)
        })
        .finally(() => {
          setBusy(null)
        })
    }

  }, [locationId]);

  function handleLocationNameChange(e) {
    updateLocation({locationName: e.target.value?.trim()})
    setTouched(true)
  }

  function handleDisplayNameChange(e) {
    updateLocation({displayName: e.target.value?.trim()})
    setTouched(true)
  }

  function handleCountryChange(e) {
    updateLocation({countryCode: e.value?.trim()})
    setCountry(e)
    setTouched(true)
  }

  function handlePopChange(v) {
    updateLocation({pop: parseInt(v)})
    setTouched(true)
  }

  function handleLatChange(v) {
    updateLocation({lat: parseFloat(v)})
    setTouched(true)
  }

  function handleLngChange(v) {
    updateLocation({lng: parseFloat(v)})
    setTouched(true)
  }

  function handleRadiusChange(v) {
    updateLocation({radius: parseFloat(v)})
    setTouched(true)
  }

  function handleStatusChange(v) {
    setStatus(v)
    setTouched(true)
  }

  function loadCountries(input, loadedOptions, additional) {
    return api.listCountries({view: "BASIC", extraParams: `${input ? `filter=countryName:${encodeURIComponent(input)}&` : ""}sort=countryName:ASC`, cursor: additional?.cursor, limit: 20})
      .then(({data, nextCursor}) => {
        log.debug("loadCountries: data=", data)
        let opt = data.map(c => {
          return {
            value: c.countryCode,
            label: c.countryCode,
            pop: c.pop
          };
        });
        let res = {
          options: opt,
        }
        if (nextCursor) {
          res = {
            ...res,
            hasMore: true,
            additional: {
              cursor: nextCursor
            }
          }
        }
        log.debug("loadClients: res=", res)
        return res
      });
  }

  function validate() {

    let opt = {focusEl: null, valid: true}

    clearAllErrors("frmMain")

    validateField("inp_locationName", location.locationName && location.locationName.trim().length > 0 , "required", opt)
    validateField("inp_displayName", location.displayName && location.displayName.trim().length > 0 , "required", opt)
    validateField("inp_pop", location.pop && (parseInt(location.pop) != NaN) && (location.pop > 0) , "required", opt)
    validateField("inp_lat", location.lat && (parseFloat(location.lat) != NaN) && (location.lat !== 0.0) , "required", opt)
    validateField("inp_lng", location.lng && (parseFloat(location.lng) != NaN) && (location.lng !== 0.0) , "required", opt)
    validateField("inp_radius", location.radius && (parseFloat(location.radius) != NaN) && (location.radius > 0) , "required", opt)

    log.debug("VALIDATE: opt=", opt)

    if (opt.focusEl) {
      document.getElementById(opt.focusEl).focus()
    }

    return opt.valid

  }

  async function handleSaveClick() {

    log.debug("handleSaveClick: invoked" )

    if (validate()) {

      setBusy(intl.msg("saving"))

      try {

        if (isAdding) {

          const payload = {
            locationName: location.locationName,
            displayName: location.displayName,
            countryCode: country.value,
            pop: location.pop,
            lat: location.lat,
            lng: location.lng,
            radius: location.radius,
            status: status.value,
          }

          log.debug("saveLocation: payload=", payload)

          const {data} = await api.insertLocation(payload)

        }
        else {

          const payload = {
            locationName: location.locationName,
            displayName: location.displayName,
            countryCode: country.value,
            pop: location.pop,
            lat: location.lat,
            lng: location.lng,
            radius: location.radius,
            status: status.value,
          }

          log.debug("saveLocation: payload=", payload)

          const {data} = await api.updateLocation(locationId, payload)

        }

        window.location = `/sys/locations`

      }
      catch (error) {
        handleError(error)
      }
      finally {
        setBusy(null)
      }
    }
    else {
      setAlert({error: intl.msg("error_invalid_form")})
    }

  }

  function handleCancelClick() {
    window.location.reload()
  }

  function handleError(error) {
    if (error && error.code) {
      log.debug("handleError: error=", error.code)
      const code = error.code
        setAlert({error: intl.msg("error_failed")})
    }
    else {
      setAlert({error: intl.msg("error_failed")})
    }
  }

  function isEditable() {
    return user.isSystem() && user.isAdminOrAbove()
  }

  return (
    tenant &&
      <>
        <MainContainer tenant={tenant} menu={MainMenu} allowIfAccessingAs={"*/*/SYSTEM"}>

          <FormHeader>
            <FormHeader.Toolbar>
              <FormHeader.Toolbar.Title>
                {intl.msg("location_title")} {" - " + (!locationId && isAdding ? "(new)" : location.locationName)}
              </FormHeader.Toolbar.Title>
              <FormHeader.Toolbar.Controls>
                {
                  touched &&
                    <>
                      <button key="key_btn_save" className="btn btn-primary" onClick={handleSaveClick}>
                        <i className="fas fa-check"></i>{intl.msg("save")}
                      </button>
                      <button key="key_btn_reset" className="btn btn-secondary" onClick={handleCancelClick}>
                        <i className="fas fa-times"></i>{intl.msg("cancel")}
                      </button>
                    </>
                }
                {/*<MoreButton/>*/}
              </FormHeader.Toolbar.Controls>
            </FormHeader.Toolbar>
            <FormHeader.Alert alert={alert}/>
          </FormHeader>

          <FormBody>

            <FormGroup>
              <FormGroup.Label htmlFor="inp_country" text={intl.msg("country")} description={intl.msg("country_description")}/>
              <FormGroup.Controls>
                <div style={{maxWidth: "600px"}}>
                  <AsyncPaginate
                    id="inp_country"
                    className="react-select"
                    classNamePrefix="react-select"
                    cacheOptions
                    value={country}
                    debounceTimeout={800}
                    defaultOptions={true}
                    loadOptions={loadCountries}
                    onChange={handleCountryChange}
                    isDisabled={!isEditable()}/>
                </div>
                <InfoBlock disabled={!isEditable()}>
                  <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
                </InfoBlock>
              </FormGroup.Controls>
            </FormGroup>

            <FormGroup>
              <FormGroup.Label htmlFor="inp_locationName" text={intl.msg("location_name")} description={intl.msg("location_name_description")}/>
              <FormGroup.Controls>
                <input id="inp_locationName" type="email" className="form-control" value={location.locationName} disabled={!isEditable()} onChange={handleLocationNameChange}/>
                <InfoBlock disabled={!isEditable()}>
                  <InfoText validate="unique">{intl.msg("location_name_helptext_unique")}</InfoText>
                  <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
                </InfoBlock>
              </FormGroup.Controls>
            </FormGroup>

            <FormGroup>
              <FormGroup.Label htmlFor="inp_displayName" text={intl.msg("location_display_name")} description={intl.msg("location_display_name_description")}/>
              <FormGroup.Controls>
                <input id="inp_displayName" type="email" className="form-control" value={location.displayName} disabled={!isEditable()} onChange={handleDisplayNameChange}/>
                <InfoBlock disabled={!isEditable()}>
                  <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
                </InfoBlock>
              </FormGroup.Controls>
            </FormGroup>

            <FormGroup>
              <FormGroup.Label htmlFor="inp_pop" text={intl.msg("location_pop")} description={intl.msg("location_pop_description")}/>
              <FormGroup.Controls>
                <div style={{width: "200px"}}>
                  <NumericInput id="inp_pop" className="form-control" style={false} min={0} precision={0} disabled={!isEditable()} value={location.pop} onChange={handlePopChange}/>
                </div>
                <InfoBlock disabled={!isEditable()}>
                  <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
                </InfoBlock>
              </FormGroup.Controls>
            </FormGroup>

            <FormGroup>
              <FormGroup.Label htmlFor="inp_lat" text={intl.msg("location_lat_lng")} description={intl.msg("location_lat_lng_description")}/>
              <FormGroup.Controls>
                <div className="d-flex d-flex-row" style={{gap: "4px"}}>
                  <NumericInput id="inp_lat" className="form-control" style={false} min={0} precision={6} disabled={!isEditable()} value={location.lat} onChange={handleLatChange}/>
                  <NumericInput id="inp_lng" className="form-control" style={false} min={0} precision={6} disabled={!isEditable()} value={location.lng} onChange={handleLngChange}/>
                  <a className="btn btn-secondary" style={{width: "30px", padding: "6px"}} href={`http://maps.google.com/maps?z=12&t=m&q=loc:${location.lat}+${location.lng}`} target="_blank">
                    <i className="far fa-compass"></i>
                  </a>
                </div>
                <InfoBlock disabled={!isEditable()}>
                  <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
                </InfoBlock>
              </FormGroup.Controls>
            </FormGroup>

            <FormGroup>
              <FormGroup.Label htmlFor="inp_radius" text={intl.msg("location_radius")} description={intl.msg("location_radius_description")}/>
              <FormGroup.Controls>
                <div style={{maxWidth: "200px"}}>
                  <div className="d-flex d-flex-row" style={{gap: "4px"}}>
                    <NumericInput id="inp_radius" className="form-control" style={false} min={0} precision={2} disabled={!isEditable()} value={location.radius} onChange={handleRadiusChange}/>
                    <div className="d-flex justify-content-center align-items-center">km</div>
                  </div>
                </div>
                <InfoBlock disabled={!isEditable()}>
                  <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
                </InfoBlock>
              </FormGroup.Controls>
            </FormGroup>

            <FormGroup>
              <FormGroup.Label htmlFor="inp_status" text={intl.msg("status")} description={intl.msg("status_description")}/>
              <FormGroup.Controls>
                <div style={{maxWidth: "200px"}}>
                  <Select
                    id="inp_status"
                    className="react-select"
                    classNamePrefix="react-select"
                    value={status}
                    options={getStatusOpts(intl)}
                    onChange={handleStatusChange}
                    isDisabled={!isEditable()}/>
                </div>
                <InfoBlock disabled={!isEditable()}>
                  <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
                </InfoBlock>
              </FormGroup.Controls>
            </FormGroup>

          </FormBody>
        </MainContainer>

      </>

  )
}
