import React, {useEffect, useState} from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import {ActionButton, Alert, FormGroup, useAlert} from "./components/FormComps"
import { useIntlEx } from "./components/IntlUtils.js"
import {default as Select} from "react-select"
import {useSolv} from "./components/SolvProvider";
import {closeDialog, ModalDialog} from "./components/DialogUtils";
import {mkHref} from "./components/AccountUtils";
import {usePaymentMethodOpts} from "./components/CreditTransactionUtils";
import getLogger from "./components/Logging.js"
import './App.css'

const log = getLogger("CreditTransactionSettleDialog")

export default function CreditTransactionSettleDialog(props)  {

  const { api, setBusy } = useSolv()
  const { intl } = useIntlEx()

  const [alert, setAlert] = useAlert()

  const {paymentMethodOpts, findPaymentMethodOpt} = usePaymentMethodOpts(f => {
    if (["BANK", "CASH", "CRYPTO", "OTHER", "PAYPAL", "TEST", "WISE"].includes(f.value)) {
      return f
    }
    else {
      return null
    }
  })

  const [paymentMethod, setPaymentMethod] = useState(findPaymentMethodOpt("BANK"))
  const [refInfo, setRefInfo] = useState(null)
  const [touched, setTouched] = useState(null)

  async function handleSettleClick() {

    try {

      let payload = {
        paymentMethodId: paymentMethod.value,
        refInfo: refInfo,
      }

      log.debug("settleCreditTransaction: payload=", payload)

      const res = await api.settleCreditTransaction(props.creditTransaction.tenant.tenantId, props.creditTransaction.creditTransactionId, payload)

      log.debug("settleCreditTransaction: data=", res)

      window.location = mkHref({suffix: "/credits", tenant: props.tenant})
    }
    catch (error) {
      log.debug("Error: error=", error)
      handleError(error)
    }
  }

  function handleError(error) {
    if (error && error.code) {
      log.debug("handleError: error=", error.code)
      const code = error.code
      switch (code) {
        case "NOT_FOUND":
          setAlert({error: intl.msg("credit_transaction_settle_error_not_found")})
          break
        default:
          setAlert({error: intl.msg("error_failed")})
          break
      }
    }
    else {
      setAlert({error: intl.msg("error_failed")})
    }
  }

  function handleRefInfoChange(e) {
    setRefInfo(e.target.value)
    setTouched(true)
  }

  function handlePaymentMethodChange(v) {
    setPaymentMethod(v)
    setTouched(true)
  }

  function handleOpenDialog() {
    setAlert(null)
  }

  function handleCloseDialog() {
    closeDialog("dlg_credit_transaction_settle")
  }


  return (

    props.tenant &&
      <ModalDialog
        dialogId="dlg_credit_transaction_settle"
        size="lg" keyboard={"true"} backdrop={props.backdrop || "static"}
        onOpen={handleOpenDialog}
        onClose={handleCloseDialog}
      >

        <ModalDialog.Header
          title={intl.msg("credit_transaction_settle_title")} description={intl.msg("credit_transaction_settle_subtitle")}
          alert={alert}
        />

        <ModalDialog.Body>

          <Alert warning={intl.msg("credit_transaction_settle_text", {credits: `${intl.num(props.creditTransaction?.credits)} ${props.creditTransaction?.tenant?.region?.currencyCode || ""}`})}/>

          <FormGroup className="mt-2">
            <FormGroup.Label htmlFor="inp_paymentMethod" text={intl.msg("payment_method")}/>
            <FormGroup.Controls>
              <div style={{width: "200px"}}>
                <Select
                  id="inp_paymentMethod"
                  className="react-select"
                  classNamePrefix="react-select"
                  value={paymentMethod}
                  options={paymentMethodOpts}
                  onChange={handlePaymentMethodChange}
                />
              </div>

            </FormGroup.Controls>
          </FormGroup>

          <FormGroup>
            <FormGroup.Label htmlFor="inp_refInfo" text={intl.msg("credits_ref_info")}/>
            <FormGroup.Controls>
              <input id="inp_refInfo" name="refInfo" type="text" className="form-control" value={refInfo} disabled={false} onChange={handleRefInfoChange} autoComplete="no"/>
            </FormGroup.Controls>
          </FormGroup>

        </ModalDialog.Body>

        <ModalDialog.Footer>
          <ModalDialog.Footer.Controls>
            <ActionButton name="cancel" onClick={handleCloseDialog}/>
            <ActionButton icon="ok" msg="settle" flavor="primary" disabled={alert || refInfo === null || refInfo?.trim()?.length === 0} onClick={handleSettleClick}/>
          </ModalDialog.Footer.Controls>
        </ModalDialog.Footer>

      </ModalDialog>
  )
}