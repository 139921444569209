import React  from 'react'
import {isValidUrl} from "./UrlUtils";
import {booleanProp} from "./ReactUtils";
import getLogger from "./Logging.js"

const log = getLogger("ValidationUtils")

export function showError(id, v) {
  let r = true
  log.debug("showError: id=", id)
  const el = document.getElementById(id)
  const formGroupEl = el.closest(".form-group")
  if (formGroupEl) {
    if (v) {
      formGroupEl.classList.add("invalid")
      const errorEl = formGroupEl.querySelectorAll(".v-" + v)
      if (errorEl && errorEl.length > 0) {
        errorEl[0].classList.add("v-error")
      }
      r = false
    }
    else {
      formGroupEl.classList.remove("invalid")
    }
  }
  return r
}  

export function clearError(id) {
  const el = document.getElementById(id)
  if (el) {
    const formGroupEl = el.closest(".form-group")
    if (formGroupEl) {
        formGroupEl.classList.remove("invalid")
    }    
  }    
}  

export function clearAllErrors(containerId) {
  const containerEl = document.getElementById(containerId)
  if (containerEl) {
    const formGroupEls = containerEl.querySelectorAll(".form-group")
    if (formGroupEls && formGroupEls.length > 0) {
      formGroupEls.forEach(function (formGroupEl) {
        formGroupEl.classList.remove("invalid")
        const vEls = formGroupEl.querySelectorAll(".v-error")
        if (vEls && vEls.length > 0) {
          vEls.forEach(function (vEl) {
            vEl.classList.remove("v-error")
          })
        }
      })
    }
  }
}

export function validateField(id, cond, v, opt) {
  if (!cond) {
    opt.valid = false
    opt.focusEl = opt.focusEl || id
    showError(id, v)
  }
  else {
    clearError(id)
  }
}

const domainNameRegex = new RegExp(/^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,20})$/)

export function validateDomainName(v) {
  return isValidUrl(v)
  // return v && v.trim().length > 0 && domainNameRegex.test(v)
}

const emailAddressRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export function validateEmailAddress(v) {  
  return v && v.trim().length > 0 && emailAddressRegex.test(v)
}

// export function validateUrl(value) {
//   return /^(?:(?:(?:https):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
// }

const validUrlPat = new RegExp('^(https:\\/\\/)?'+ // protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
  '(\\#[-a-z\\d_]*)?$','i')

export function validateUrl(url, options) {
  if (url) {
    if (options && (true === options.relaxed)) {
      if (!url.startsWith("https://")) {
        url = `https://${url}`
      }
    }
    try {
      const u = new URL(url)
      if (u.protocol === "https:") {
        if (validUrlPat.test(url)) {
          if (options && options.disallowedDomains && Array.isArray(options.disallowedDomains)) {
            if (options.disallowedDomains.find((d) => u.host.endsWith(d))) {
              return false
            }
          }
          if (options && options.allowedDomains && Array.isArray(options.allowedDomains)) {
            if (!options.allowedDomains.find((d) => u.host.endsWith(d))) {
              return false
            }
          }
          return true
        }
      }
    }
    catch (e) {

    }
  }
  return false
}

export function InfoBlock(props) {
  log.debug(">>>Z: InfoBlock: props=", booleanProp(props, "disabled"))
  return (
    booleanProp(props, "disabled") === false ? (
      booleanProp(props, "ordered") === true ? (
        <ol className="helptext">
          {props.children}
        </ol>
      ) : (
        <ul className="helptext">
          {props.children}
        </ul>
      )
    ) : (
      <></>
    )
  )
}

export function InfoText(props) {
  return (
    <>
      {
        !props.disabled &&
          <li className={props.validate ? `v v-${props.validate}` : props.warning ? "v v-warning" : "d"}>
            <span>
              {props.children}
            </span>
          </li>
      }
    </>
  )
}
