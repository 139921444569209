export const environmentOpts = [
  {
    label: "DEV",
    value: "DEV",
  },
  {
    label: "LIVE",
    value: "LIVE",
  },
]

export const tenantTypeOpts = [
  {
    label: "CLIENT",
    value: "CLIENT"
  },
  {
    label: "RESELLER",
    value: "RESELLER"
  },
  {
    label: "SYSTEM",
    value: "SYSTEM"
  },
]

export const tenantPlanOpts = [
  {

    label: "BUSINESS_A",
    value: "BUSINESS_A"
  },
  {
    label: "BUSINESS_B",
    value: "BUSINESS_B"
  },
  {
    label: "RESELLER",
    value: "RESELLER"
  },
  {
    label: "SYSTEM",
    value: "SYSTEM"
  },
]

export const brandOpts = [
  {
    label: "ELCT",
    value: "ELCT"
  },
  {
    label: "PETSOLV",
    value: "PETSOLV"
  },
  {
    label: "SOLV",
    value: "SOLV"
  },
]

