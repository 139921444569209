import React, { useState, useEffect } from "react"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { AsyncPaginate } from "react-select-async-paginate"
import {default as Select} from "react-select";
import { v4 as uuidv4 } from 'uuid'
import ReCAPTCHA from "react-google-recaptcha"
import {useSolv} from "../components/SolvProvider"
import {useIntlEx} from "../components/IntlUtils"
import {Alert, FormGroup, Icon, MainContainer, SelectList, useAlert} from "../components/FormComps"
import {PageDialogContainer, PageDialog, PageDialogMainPanel, PageDialogSidePanel} from "../components/PageDialog"
import {GoogleButton} from "../components/GoogleButton"
import {LinkedInButton } from "../components/LinkedInButton"
import {isBlankString} from "../components/StringUtils"
import {validateEmailAddress} from "../components/ValidationUtils"
import {PageDialogHeader, PageDialogFooter, PageDialogSider} from "./PageDialogUtils";
import {mkExtraParams} from "../components/TableComps";
import getLogger from "../components/Logging.js"
import '../App.css'

const log = getLogger("Signup")

function hashToStep(hash) {
  if (hash) {
    if (hash.startsWith("#")) {
      return hash.slice(1).toUpperCase()
    }
    else {
      return hash.toUpperCase()
    }
  }
  else {
    return "START"
  }
}

export default function Signup(props) {

  const {brandId, api, auth: {signup, generateMagicLink, registerDirectSignup}, env, setBusy, setFatal} = useSolv()
  const {intl} = useIntlEx()

  const params = useParams()
  const location = useLocation()
  const history = useHistory()

  const queryParams = new URLSearchParams(location.search)

  const [alert, setAlert] = useAlert()

  const [signupCode, setSignupCode] = useState(params.signupCode)
  const [magicCode, setMagicCode] = useState(params.magicCode)
  const [signupInfo, setSignupInfo] = useState(null)
  const [fullName, setFullName] = useState(queryParams.get(null))
  const [emailAddress, setEmailAddress] = useState(queryParams.get("emailAddress"))
  const [recaptchaResponse, setReCaptchaResponse] = useState(null)
  const [tenantName, setTenantName] = useState(null)
  const [region, setRegion] = useState(null)
  const [step, setStep] = useState("START")
  const [regionSelectKey, setRegionSelectKey] = useState(null)
  const [googlePayload, setGooglePayload] = useState(null)
  const [googleAccounts, setGoogleAccounts] = useState(null)
  const [googleAccount, setGoogleAccount] = useState(null)

  useEffect(() => {
      setStep(hashToStep(location.hash))
  },[location])

  useEffect(() => {
    if (magicCode) {
      signupWithMagicCode(magicCode)
    }
  }, [magicCode])

  useEffect(() => {
    if (signupCode) {
      if (["START", "EMAIL"].includes(step)) {
        setBusy(intl.msg("loading"))
        api.getSignupInfo(signupCode)
          .then(({data}) => {
            setSignupInfo(data)
            if (data?.inviterTenant?.brandId !== brandId) {
              handleError({code: "INVALID_REFERRAL_CODE"})
            }
            if (data?.inviteeUser?.emailAddress) {
              setEmailAddress(data.inviteeUser.emailAddress)
            }
          })
          .catch((error) => {
            log.debug(">>>Z: signupInfo: error=", error)
            handleError(error)
          })
          .finally(() => {
            setBusy(null)
          })
      }
    }
  }, [])

  function queryParamsToJson() {
    let result = {}
    for (const [key, value] of queryParams.entries()) {
      result[key] = value
    }
    return result
  }

  function handleSignUpWithEmailClick() {
    setAlert(null)
    window.location = mkLink("/signup", "#email")  //`/signup/${signupCode}?${queryParams.toString()}#email`

    // setAlert(null)
    // let rfr = ""
    // if (props.location?.state?.referrer) {
    //   rfr = `?rfr=${encodeURIComponent(`${props.location.state.referrer.pathname}${props.location.state.referrer.search}${props.location.state.referrer.hash}`)}`
    // }
    // window.location.replace(`${window.location.pathname}${rfr}#email`)

  }

  async function handleGoogleAccountChange(v) {
    setGoogleAccount(v)
    let payload = googlePayload
    payload.emailAddress = v.value
    log.debug("handleGoogleAccountChange: payload=", v, payload)
    await doSignup(payload)
  }

  function handleEmailAddressChange(e) {
    e.preventDefault()
    setEmailAddress(e.target.value)
  }

  function handleFullNameChange(e) {
    e.preventDefault()
    setFullName(e.target.value)
  }

  async function handleGoogleLoginSuccess(response) {
    log.debug("googleHandleSuccessfulLogin: response=", response)
    let payload = {}
    if (signupInfo) {
      payload = {
        op: "SIGN_UP",
        idProvider: "GOOGLE",
        signupCode: signupCode,
        signupType: signupInfo.signupType,
        signupParams: queryParamsToJson(),
        tenantName: response.profileObj.emailAddress,
        profileObj: response.profileObj,
      }
    }
    else {
      payload = {
        op: "SIGN_UP",
        idProvider: "GOOGLE",
        signupCode: null,
        signupType: "DIRECT",
        signupParams: queryParamsToJson(),
        profileObj: response.profileObj,
      }
    }
    setGooglePayload(payload)
    doSignup(payload)
  }

  function handleGoogleLoginError(response) {
    log.debug(">>>Z: googleHandleFailedLogin", response)
    setAlert({error: intl.msg("error_failed_google_auth")})
  }

  async function handleMicrosoftLoginSuccess(response){
    try {
      setBusy(intl.msg("working"))
      let payload = {}
      if (signupInfo) {
        payload = {
          idProvider: "MICROSOFT",
          signupCode: signupCode,
          signupType: signupInfo.signupType,
          signupParams: queryParamsToJson(),
          tenantName: response.account?.username,
          account: response.account
        }
      }
      else {
        payload = {
          idProvider: "MICROSOFT",
          signupCode: null,
          signupType: "DIRECT",
          signupParams: queryParamsToJson(),
          account: response.account
        }
      }
      doSignup(payload)
    }
    catch (err) {
      handleError(err)
    }
    finally {
      setBusy(null)
    }
  }

  function handleMicrosoftLoginError(err) {
    log.error("handleMicrosoftLoginError: ERROR=", err)
    setAlert({error: intl.msg("error_failed_microsoft_auth")})
  }


  async function handleLinkedInLoginSuccess(response) {
    let payload = {}
    if (signupInfo) {
      payload = {
        op: "SIGN_UP",
        idProvider: "LINKEDIN",
        signupCode: signupCode,
        signupType: signupInfo.signupType,
        signupParams: queryParamsToJson(),
        code: response,
      }
    }
    else {
      payload = {
        idProvider: "LINKEDIN",
        sigmupCode: null,
        signupType: "DIRECT",
        signupParams: queryParamsToJson(),
        code: response,
      }
    }
    doSignup(payload)
  }

  function handleLinkedInLoginError(response) {
    log.debug("handleLinkedInLoginError", response)
    setAlert({error: intl.msg("error_failed_linkedin_auth")})
  }

  async function handleEmailContinueClick() {
    try {
      try {
        setBusy(intl.msg("working"))
        if (isBlankString(fullName)) {
          setAlert({error: intl.msg("error_invalid_input_full_name")})
        }
        else if (isBlankString(emailAddress) || !validateEmailAddress(emailAddress)) {
          setAlert({error: intl.msg("error_invalid_input_email_address")})
        }
        else if (!recaptchaResponse) {
          setAlert({error: intl.msg("error_invalid_input_recaptcha")})
        }
        else {
          let payload = {}
          if (signupInfo) {
            payload = {
              op: "SIGN_UP",
              signupCode: signupCode,
              signupType: signupInfo.signupType,
              signupParams: queryParamsToJson(),
              fullName: fullName,
              emailAddress: emailAddress,
              recaptcha: recaptchaResponse,
              referrer: queryParams.get("rfr"),
            }
          }
          else {
            payload = {
              op: "SIGN_UP",
              signupType: "DIRECT",
              fullName: fullName,
              emailAddress: emailAddress,
              recaptcha: recaptchaResponse,
              referrer: queryParams.get("dest"),
            }
          }
          await generateMagicLink(payload)

          setAlert(null)
          queryParams.set("emailAddress", emailAddress)

          window.location = mkLink("/signup", "#check_email") //`/signup/${signupCode}?${queryParams.toString()}#check_email`
        }
      }
      finally {
        setBusy(null)
      }
    }
    catch (err) {
      log.debug("handleEmailContinueClick: err=", err)
      handleError(err)
    }
  }

  function signupWithMagicCode(magicCode) {
    log.debug("signupWithMagicCode: magicCode=", magicCode)
    doSignup({
      idProvider: "MAGICLINK",
      code: magicCode
    })
  }

  function doSignup(payload) {
    setAlert(null)
    setBusy(intl.msg("working"))
    signup({...payload, op: "SIGN_UP", appVer: env.APP_VERSION})
      .then(({data}) => {
        doCompleteOnboarding()
          .catch((error) => {
            handleError(error)
          })
      })
      .catch((error) => {
        log.debug("doSignup: error=", error)
        setStep("START")
        handleError(error)
      })
      .finally(() => {
        setBusy(null)
      })
  }

  function doCompleteOnboarding() {
    log.debug("completeOnboarding: invoked")
    // window.location = "/"
    return api.updateOnboarded()
      .then(({data}) => {
        log.debug("completeOnboarding: updateOnboarded: res=", data)
        window.location = "/"
      })
      .catch((error) => {
        setAlert({error: intl.msg("error_failed")})
      })
  }

  function handleResendVerificationClick() {
    setAlert(null)
    window.location = mkLink("/signup", "#email") //`/signup/${signupCode}#email`
  }

  function searchRepresentedRegions(search, loadedOptions, additional) {
    return api.listRepresentedRegions(env.DEFAULT_INDUSTRY_ID, search, additional?.cursor)
      .then(({data, nextCursor}) => {
        let opt = data.map(c => {
          return ({
            ...c,
            value: c.regionId,
            label: c.regionName,
          })
        });
        let res = {
          options: opt,
        }
        if (nextCursor) {
          res = {
            ...res,
            hasMore: true,
            additional: {
              cursor: nextCursor
            }
          }
        }
        log.debug("searchRegions: res=", res)
        return res
      })
  }

  function handleRegionChange(v) {
    setRegion(v)
  }

  function handleTenantNameChange(e) {
    setTenantName(e.target.value)
  }

  async function handleDirectSignupContinueClick() {

    if (isBlankString(tenantName)) {
      setAlert({error: intl.msg("error_invalid_input_tenant_name")})
    }
    else if (!region) {
      setAlert({error: intl.msg("error_invalid_input_region")})
    }
    else if (!recaptchaResponse) {
      setAlert({error: intl.msg("error_invalid_input_recaptcha")})
    }
    else {

      const payload = {
        brandId: brandId,
        regionId: region.value,
        industryId: env.DEFAULT_INDUSTRY_ID,
        tenantName: tenantName,
        recaptcha: recaptchaResponse
      }

      setBusy(intl.msg("saving"))
      try {
        const {data} = await registerDirectSignup(payload)
        log.debug("handleDirectSignupContinueClick: data=", data)
        setAlert(null)
        window.location = `/signup/${data.directSignupRequestId}`
      }
      catch (error) {
        handleError(error)
      }
      finally {
        setBusy(null)
      }
    }

  }

  function handleError(error) {
    log.debug("handleError: error=", error)
    if (error && error.code) {
      log.debug("handleError: code=", error.code)
      const code = error.code
      switch (code) {
        case "MULTIPLE_GOOGLE_ACCOUNTS":
          if (error.details?.accounts) {
            const opts = error.details.accounts.map(a => {
              return ({
                label: `${a.tenantName} (${a.emailAddress })`,
                value: a.emailAddress,
              })
            })
            opts.sort((a, b) => a.label.localeCompare(b.label));
            setGoogleAccounts(opts)
            setGoogleAccount(null)
            setAlert(null)
            setStep("SELECT_GOOGLE_ACCOUNT")
          }
          else {
            setAlert({error: intl.msg("error_failed")})
          }
          break
        case "UNKNOWN_SIGNUP_CODE":
        case "INVALID_SIGNUP_CODE":
        case "INVALID_REFERRAL_CODE":
        case "ILLEGAL_REFERRAL":
          setAlert({error: intl.msg("signup_error_invalid_signup_code")})
          break
        case "UNKNOWN_INVITER":
        case "INVALID_INVITER":
          setAlert({error: intl.msg("signup_error_invalid_inviter")})
          break
        case "INVALID_INVITEE":
          setAlert({error: intl.msg("signup_error_invalid_invitee")})
          break
        case "DUPLICATE_KEY":
        case "DUPLICATE_INVITEE_USER":
          setAlert({error: intl.msg("signup_error_duplicate_user", {emailAddress: error.details?.emailAddress || ""})})
          break
        case "DUPLICATE_INVITEE_TENANT":
          setAlert({error: intl.msg("signup_error_duplicate_tenant", {tenantName: error.details?.tenantName || ""})})
          break
        case "UNAUTHORIZED":
        case "NOT_FOUND":
          setAlert({error: intl.msg("error_unknown_user")})
          break
        case "BANNED_INVITER":
        case "BANNED_USER":
        case "BANNED_REMOTE_ADDRESS":
          setAlert({error: intl.msg("error_banned_user")})
          break
        case "BANNED_EMAIL_ADDRESS":
          setAlert({error: intl.msg("error_banned_email_address")})
          break
        case "INVALID_EMAIL_ADDRESS":
          setAlert({error: intl.msg("error_invalid_email_address")})
          break
        case "INVALID_RECAPTCHA":
          setAlert({error: intl.msg("error_invalid_recaptcha")})
          break
        case "INVALID_MAGIC_LINK":
          setAlert({error: intl.msg("error_invalid_magic_link")})
          break
        case "DUPLICATE_MAGIC_LINK":
          setAlert({error: intl.msg("error_duplicate_magic_link")})
          break
        case "ONBOARDING":
          doCompleteOnboarding()
          break
        case "UNAVAILABLE":
          window.location = "/unavailable"
          break
        default:
          setAlert({error: intl.msg("error_failed")})
      }
    }
    else {
      setAlert({error: intl.msg("error_failed")})
    }
  }

  function mkLink(prefix, hash) {
    if (props.location?.state?.referrer) {
      queryParams.set("dest", `${props.location.state.referrer.pathname}${props.location.state.referrer.search}${props.location.state.referrer.hash}`)
    }
    return `${prefix || "/signup"}/${signupCode}?${queryParams.toString()}${hash || ""}`
  }

  return (
    <MainContainer>
      <PageDialogContainer>
        <PageDialog header={PageDialogHeader} sider={PageDialogSider} footer={PageDialogFooter} size="md" align="center">
            {
              signupCode && signupInfo ? (
                ("EMAIL" === step) ? (
                  <>
                    <PageDialogMainPanel title={intl.msg("signup_with_email_title")} alert={alert}>
                      <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>

                        <p className="mt-3">
                          {intl.msg("signup_with_email_enter_info")}
                        </p>

                        {/*<FormGroup>*/}
                        {/*  <FormGroup.Label text={intl.msg("full_name")}/>*/}
                        {/*  <FormGroup.Control>*/}
                            <input className="form-control" key="inp_fullName" id="inp_fullName" name="fullName" style={{width: "300px", color: "var(--form-control-text-color)", backgroundColor: "var(--form-control-background-color)", border: "1px solid var(--form-control-border-color)"}} placeHolder={intl.msg("your_full_name_placeholder")} value={fullName} onChange={handleFullNameChange}/>
                        {/*  </FormGroup.Control>*/}
                        {/*</FormGroup>*/}

                        {
                          !signupInfo?.inviteeUser?.emailAddress &&
                            <input className="form-control mt-3" key="inp_emailAddress" id="inp_emailAddress" name="emailAddress" style={{width: "300px", color: "var(--form-control-text-color)", backgroundColor: "var(--form-control-background-color)", border: "1px solid var(--form-control-border-color)"}} placeHolder={intl.msg("your_email_address_placeholder")} value={emailAddress} onChange={handleEmailAddressChange}/>
                        }

                        <div id="fg_captcha" className="mt-3">
                          <ReCAPTCHA
                              sitekey={`${env.GOOGLE_RECAPTCHA_SECRET}`}
                              onChange={(e) => setReCaptchaResponse(e)}
                              theme={window.getComputedStyle(document.documentElement).getPropertyValue("--theme")}/>
                        </div>
                        <button className="btn btn-primary mt-3" style={{minWidth: "100px", padding: "8px", marginTop: "10px"}} onClick={handleEmailContinueClick}>
                          {intl.msg("continue")}
                        </button>
                        <div className="mt-3">
                          {intl.msg("signup_use_another_means", {a: chunks => <a className="text-link" href={mkLink("/signup")}>{chunks}</a>})}
                        </div>
                      </div>
                    </PageDialogMainPanel>
                  </>

                ) : ("CHECK_EMAIL" === step) ? (
                  <>
                    <PageDialogMainPanel title={intl.msg("signup_with_email_check_your_email_title")} alert={alert}>
                      <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <p className="mt-3">
                          {intl.msg("signup_with_email_check_your_email_text", {brand: "SOLV", emailAddress: emailAddress, b: chunks => <strong>{chunks}</strong>, em: chunks => <em>{chunks}</em>, p: chunks  => <p className="mt-3">{chunks}</p>})}
                        </p>
                        <button type="button" className="btn btn-primary mt-3" onClick={handleResendVerificationClick}>{intl.msg("resend")}</button>
                      </div>
                    </PageDialogMainPanel>
                  </>

                ) : ("SELECT_GOOGLE_ACCOUNT" === step) ? (
                  <>
                    <PageDialogMainPanel title={intl.msg("signup_select_account_title")} alert={alert}>
                      <p className="mt-3">
                        {intl.msg("signup_select_account_text", {brand: "SOLV", emailAddress: emailAddress, b: chunks => <strong>{chunks}</strong>, em: chunks => <em>{chunks}</em>, p: chunks  => <p className="mt-3">{chunks}</p>})}
                      </p>
                      <SelectList
                        id="inp_account"
                        value={googleAccount}
                        options={googleAccounts}
                        menuIsOpen={true}
                        onChange={handleGoogleAccountChange}
                        isDisabled={false}/>
                    </PageDialogMainPanel>
                  </>
                ) : (
                  <>
                    <PageDialogMainPanel title={intl.msg(signupInfo ? `signup_${signupInfo.signupType.toLowerCase()}_title` : `signup_direct_title`)} alert={alert}>
                      <p className="mt-3">
                        {
                          signupInfo ? (
                              intl.msg(`signup_${signupInfo.signupType.toLowerCase()}_text`, {
                                inviterUserName: signupInfo.inviterUser?.displayName,
                                inviterTenantName: signupInfo.inviterTenant?.tenantName,
                                inviteeTenantName: signupInfo?.inviteeTenant?.tenantName,
                                inviteeEmailAddress: signupInfo?.inviteeUser?.emailAddress,
                                referralCode: signupInfo.signupCode?.toUpperCase(),
                                brandId: brandId
                              })
                          ) : (
                              <></>
                          )
                        }
                      </p>
                      <div>
                        <GoogleButton
                          onSuccess={handleGoogleLoginSuccess}
                          onError={handleGoogleLoginError}
                          buttonText={intl.msg("signup_with_google")}/>
                      </div>
                      {/*<div className="mt-2">*/}
                      {/*  <MicrosoftButton*/}
                      {/*    onSuccess={handleMicrosoftLoginSuccess}*/}
                      {/*    onError={handleMicrosoftLoginError}*/}
                      {/*    buttonText={intl.msg("signup_with_microsoft")}/>*/}
                      {/*</div>*/}
                      <div className="mt-2">
                        <LinkedInButton
                          onSuccess={handleLinkedInLoginSuccess}
                          onError={handleLinkedInLoginError}
                          buttonText={intl.msg("signup_with_linkedin")}/>
                      </div>
                      <div className="mt-2" style={{ flexDirection: "column", alignItems: "center", }}>
                        <button className="btn-signin btn-signin-email" type="button" onClick={handleSignUpWithEmailClick}>
                          <div className="btn-signin-icon">
                            <Icon name="envelope2"/>
                          </div>
                          <span className="btn-signin-text">{intl.msg("signup_with_email")}</span>
                        </button>
                      </div>
                      <div className="mt-4" style={{fontSize: "8pt"}}>
                        {intl.msg("signup_by_clicking_signup", {a1: chunks => <a href="https://sassets.solv.technology/legal/terms.html" target="_blank">{chunks}</a>, a2: chunks => <a href="https://sassets.solv.technology/legal/privacy.html" target="_blank">{chunks}</a>})}
                      </div>
                      <div className="mt-4" style={{borderTop: "1px solid var(--form-divider-color)"}}>
                        {intl.msg("signup_already_have_an_account")}
                      </div>
                    </PageDialogMainPanel>
                  </>
                )
              ) : (
                <>
                  <PageDialogMainPanel title={intl.msg("signup_create_account_title", {brand: brandId})} alert={alert}>

                    <Alert className="mt-2" attention={intl.msg("signup_direct_attention")}/>

                    <p className="mt-3 mb-0">
                      {intl.msg("signup_direct_init_text", {brand: brandId})}
                    </p>

                    <div className="row d-flex flex-column justify-content-center align-items-center mt-4">

                      <div>
                        <div className="" style={{width: "300px"}}>
                          <input className="form-control" key="inp_tenantName" id="inp_tenantName" style={{width: "100%", color: "var(--form-control-text-color)", backgroundColor: "var(--form-control-background-color)", border: "1px solid var(--form-control-border-color)"}} placeHolder={intl.msg("signup_account_name_placeholder")} value={fullName} onChange={handleTenantNameChange}/>
                        </div>
                      </div>

                      <div className="mt-2" style={{width: "300px"}}>
                        <AsyncPaginate
                          key={regionSelectKey}
                          id="inp_regions"
                          className="react-select"
                          classNamePrefix="react-select"
                          cacheOptions
                          value={region}
                          placeholder={intl.msg("signup_region_placeholder")}
                          debounceTimeout={800}
                          defaultMenuIsOpen={false}
                          defaultOptions={true}
                          menuPosition={"fixed"}
                          loadOptions={searchRepresentedRegions}
                          onChange={handleRegionChange}
                          isDisabled={false}/>
                      </div>

                      <div id="fg_captcha" className="mt-2">
                        <ReCAPTCHA
                          sitekey={`${env.GOOGLE_RECAPTCHA_SECRET}`}
                          onChange={(e) => setReCaptchaResponse(e)}
                          theme={window.getComputedStyle(document.documentElement).getPropertyValue("--theme")}/>
                      </div>

                      <button className="btn btn-primary mt-3" onClick={handleDirectSignupContinueClick}>{intl.msg("continue")}</button>
                    </div>

                    <div className="mt-4" style={{fontSize: "8pt"}}>
                      {intl.msg("signup_by_clicking_continue", {a1: chunks => <a href="https://sassets.solv.technology/legal/terms.html" target="_blank">{chunks}</a>, a2: chunks => <a href="https://sassets.solv.technology/legal/privacy.html" target="_blank">{chunks}</a>})}
                    </div>
                    <div className="mt-4" style={{borderTop: "1px solid var(--form-divider-color)", paddingTop: "20px"}}>
                      {intl.msg("signup_already_have_an_account")}
                    </div>
                  </PageDialogMainPanel>
                </>
              )
            }
        </PageDialog>
      </PageDialogContainer>
    </MainContainer>
  )
}