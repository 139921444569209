import React, {useState} from "react"
import {Editor, EditorProvider, Toolbar, useEditorState} from "react-simple-wysiwyg";
import {useIntlEx} from "../components/IntlUtils";
import getLogger from "../components/Logging"
import '../App.css'
import './PetSolv.css'
import {Icon} from "../components/FormComps";

const log = getLogger("ContentInput")

export function ContentInput(props) {

  const {intl} = useIntlEx()

  // const [value, setValue] = useState(props.value)
  const [toolbarDisplay, setToolbarDisplay] = useState(false)

  function handleInputChange(v) {
    // setValue(v)
    if ("function" === typeof(props.onChange)) {
      props.onChange(v)
    }
  }

  function handleInputFocus(e) {
    setToolbarDisplay(true)
    if ("function" === typeof(props.onFocus)) {
      props.onFocus(e)
    }
  }

  function handleInputBlur(e) {
    setToolbarDisplay(false)
    if ("function" === typeof(props.onBlur)) {
      props.onBlur(e)
    }
  }

  function createButton2(
    title,
    content,
    command,
  ) {
    ButtonFactory.displayName = title.replace(/\s/g, '');

    return ButtonFactory;

    function ButtonFactory(props) {
      const editorState = useEditorState();
      const { $el, $selection } = editorState;

      let active = false;
      if (typeof command === 'string') {
        switch (command) {
          case "strikeThrough":
            active = !!$selection && isSelectionInTag("strike")
            break
          default:
            active = !!$selection && document.queryCommandState(command);
        }
      }

      function onAction(e) {
        e.preventDefault();

        if (document.activeElement !== $el) {
          // eslint-disable-next-line no-unused-expressions
          $el?.focus();
        }

        if (typeof command === 'function') {
          command(editorState);
        }
        else {
          switch (command) {
            case "highlight":
              const html = createStyleSpan({color: "red"})
              document.execCommand("insertHtml", false, html)
              break
            default:
              document.execCommand(command);
          }
        }
      }

      if (editorState.htmlMode) {
        return null;
      }

      return (
        <button
          className="rsw-btn"
          data-active={active}
          onMouseDown={onAction}
          tabIndex={-1}
          title={title}
          type="button"
          {...props}
        >
          {content}
        </button>
      );
    }
  }

  function createStyleSpan(style) {
    const range = window.getSelection().getRangeAt(0)
    const content = range.cloneContents()
    const span = document.createElement('span')
    span.style = style
    span.appendChild(content)
    var htmlContent = span.innerHTML
    range.insertNode(span)
    return htmlContent
  }

  function isSelectionInTag(tag) {
    tag = tag.toUpperCase()
    let currentNode = window.getSelection().focusNode;
    while (currentNode?.id !== 'inp_creativeContent') {
      log.debug(">>>Z: tagName=", currentNode?.tagName)
      if (currentNode.tagName === tag) return true
      currentNode = currentNode.parentNode
    }
    return false
  }

  const BtnBold = createButton2(intl.msg("edit-bold"), <Icon name="edit-bold"/>, 'bold')
  const BtnItalic = createButton2(intl.msg("edit-italic"), <Icon name="edit-italic"/>, 'italic')
  const BtnUnorderedList = createButton2(intl.msg("edit-ordered-list"), <Icon name="edit-ul"/>, 'insertUnorderedList')
  const BtnOrderedList = createButton2(intl.msg("edit-unordered-list"), <Icon name="edit-ol"/>, 'insertOrderedList')
  const BtnHighlight = createButton2(intl.msg("edit-highlight"), <Icon name="edit-highlight"/>, "strikeThrough")
  // const BtnHighlight = createButton2(intl.msg("edit-highlight"), <i className="fas fa-highlighter"></i>, ({$selection}) => {
  //   log.debug(">>>Z: selection=", $selection)
  //   document.execCommand('underline', false, null);
  // })

  return (
    <EditorProvider>
      <Editor
        id={props.id || "richtext-input"}
        containerProps={props.style}
        value={props.value}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
      />
      <Toolbar style={{display: (toolbarDisplay ? "flex" : "none"), flexDirection: "row", position: "relative", width: "100%", borderRadius: "0"}}>
        <BtnBold />
        <BtnItalic />
        <BtnHighlight />
        <BtnUnorderedList/>
        <BtnOrderedList/>
        {/*<BtnLink />*/}
      </Toolbar>
    </EditorProvider>
  )
}