import React from "react"
import {useIntlEx} from "../components/IntlUtils";
import {PageDialogSidePanel} from "../components/PageDialog";
import getLogger from "../components/Logging.js"
const log = getLogger("PageDialog")

export function PageDialogHeader(props) {
  // const mode = getComputedStyle(document.documentElement).getPropertyValue("--mode") || "dark"
  return (
    <div className="p-3">
      <img src={`https://sassets.elct.io/brand/elct-logo-color-dark.svg`} width="auto" height="30"/>
    </div>
  )
}

export function PageDialogFooter(props) {
  const {intl} = useIntlEx()
  return (
      <>
        <div>
          &copy; SOLV Technology PTE LTD
          {/*&copy; <a className="text-link" href="https://www.solv.technology" title="SOLV Home Page" target="_blank">SOLV Technology PTE LTD</a>*/}
        </div>
        <div>
          <a className="text-link mr-2" href="https://www.elct.io" title="ELCT Home" target="_blank">www</a>
          <a className="text-link mr-2" href="https://www.elct.io/legal" title="ELCT Policies" target="_blank">legal</a>
          <a className="text-link" href="https://help.elct.io" title="SOLV Support & Knowledgebase" target="_blank">{intl.msg("help").toLowerCase()}</a>
        </div>
      </>
  )
}

export function PageDialogSider(props) {
  const {intl} = useIntlEx()
  const theme = getComputedStyle(document.documentElement).getPropertyValue("--theme") || "dark"
  return (
      <PageDialogSidePanel {...props}>
        <div className="d-flex flex-column h-100">
          <div className="d-flex flex-column align-items-center justify-content-center w-100 mb-auto mt-1">
            <img src={`https://sassets.elct.io/brand/elct-logo-color-dark.svg`} width="150" height="25"/>
            {/*<p style={{fontSize: "9pt"}}>{intl.msg("elct_pagedialog_sider_title")}</p>*/}
          </div>
          <div>
            <div className="mb-2">
              <p style={{fontSize: "12pt"}}>{intl.msg("elct_pagedialog_sider_title")}</p>
            </div>
            <p className="m-0 p-0">{intl.msg("elct_pagedialog_sider_text", {a_learn: chunks => <><a className="icon-link" href="https://www.elct.io" title={chunks} target="_blank"><i className="fas fa-info-circle"></i></a></>})}</p>
          </div>
        </div>
      </PageDialogSidePanel>
  )
}

