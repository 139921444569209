import React, {useEffect, useRef, useState} from 'react'
import {googleLogout} from '@react-oauth/google';
import {useResizeDetector} from "react-resize-detector";
import {useSolv} from "./components/SolvProvider"
import {useIntlEx} from "./components/IntlUtils.js"
import {Anchor, DropdownMenuDivider, DropdownMenuItem} from "./components/FormComps";
import {AboutSolvDialog} from './AboutSolvDialog.js'
import getLogger from "./components/Logging.js"
import './App.css'
import {usePrefs} from "./components/PrefUtils";

const log = getLogger("MainMenu")

export default function MainMenu(props) {

  const {brandId, session: {user}, signout, setBusy} = useSolv()
  const {deletePrefs} = usePrefs()
  const {intl } = useIntlEx()

  const [tenant, setTenant] = useState(null)

  const { width, height, ref } = useResizeDetector()
  const homeLinkRef = useRef()

  useEffect(() => {
    if (props) {
      // log.debug("useEffect: tenant=", props.tenant)
      setTenant(props.tenant)
    }
  },[])

  function handleSignout() {
    setBusy(intl.msg("working"))
    deletePrefs()
    signout()
      .then((data) => {
        // log.debug("handleSignout: data=", data)
        googleLogout()
      })
      .catch((err) => {
        log.error(err)
      })
      .finally(() => {
        setBusy(null)
      })
  }

  function renderLogo() {

    const skinEl = document.getElementById("main-container") || document.documentElement;
    const mode = window.getComputedStyle(skinEl).getPropertyValue("--mode")?.trim()?.toLowerCase() || "dark"
    let logoBaseUri = window.getComputedStyle(skinEl).getPropertyValue("--logo-uri")?.trim()?.replaceAll("\"", "")?.replaceAll("{brandId}", brandId.toLowerCase())?.replaceAll("{mode}", mode.toLowerCase())
    let logoSize = (window.getComputedStyle(skinEl).getPropertyValue("--logo-size").trim()?.replaceAll("\"", "") || "44x14").split("x");

    let logoColorUri = logoBaseUri.replaceAll("{color}", "color")
    let logoMonoUri = logoBaseUri.replaceAll("{color}", "mono")
    let logoDefaultUri = logoMonoUri

    if (homeLinkRef.current && homeLinkRef.current.classList.contains("brand-link-active")) {
      logoDefaultUri = logoColorUri
    }

    return (
      <img alt="logo" src={logoDefaultUri} onMouseOver={e => e.currentTarget.src = logoColorUri} onMouseOut={e => e.currentTarget.src = logoMonoUri} width={logoSize[0]} height={logoSize[1]}/>
    )
  }

  useEffect(() => {
    // log.debug("height=", height)
    document.querySelector(':root').style.setProperty("--main-menu-height", height)
  }, [height])


  return (
    <>
      <nav ref={ref} className="main-menu fixed-top">
        {
          <>
            <div className="items">

              <ul className="items" style={{flex: "1", justifyContent: "left"}}>

                <li className="brand-item">
                  <Anchor ref={homeLinkRef} href="/" activeClassName="brand-link-active" user={user} tenant={tenant} match="EXACT">
                    {renderLogo()}
                  </Anchor>
                </li>

                {
                  tenant && (tenant.accessingAs("*/MANAGER/*") || (tenant.accessingAs("*/MEMBER/*") && !["INVITING", "ONBOARDING"].includes(tenant.status))) &&
                    <>
                      {
                        user && user.isBasicOrAbove() && (user.isSystemUser() || user.isResellerUser()) &&
                          <li className="menu-item">
                            <Anchor href="/clients" user={user} tenant={tenant}>
                              {intl.msg("clients")}
                            </Anchor>
                          </li>
                      }

                      {
                        user && user.isBasicOrAbove() && (user.isSystemUser() || (user.isResellerUser() && tenant.hasSubTenants())) &&
                          <li className="menu-item">
                            <Anchor href="/resellers" user={user} tenant={tenant}>
                              {intl.msg("resellers")}
                            </Anchor>
                          </li>
                      }

                      {
                        user && ((user.isClient() && user.isBasicOrAbove()) || (user.isReseller() && user.isAdminOrAbove()) || (user.isSystem() && user.isBasicOrAbove())) &&
                          <li className="menu-item">
                            <Anchor href="/broadcasts" user={user} tenant={tenant}>
                              {intl.msg("broadcasts")}
                            </Anchor>
                          </li>
                      }

                      {/*{*/}
                      {/*  user && user.isBasicOrAbove() &&*/}
                      {/*    <li className="menu-item">*/}
                      {/*      <Anchor href="/templates" user={user} tenant={tenant}>*/}
                      {/*        {intl.msg("templates")}*/}
                      {/*      </Anchor>*/}
                      {/*    </li>*/}
                      {/*}*/}

                      {
                        user && user.isRestrictedOrAbove() &&
                          <li className="menu-item">
                            <Anchor href="/members" user={user} tenant={tenant}>
                              {intl.msg("members")}
                            </Anchor>
                          </li>
                      }

                      {
                        user && user.isBasicOrAbove() &&
                          <li className="menu-item">
                            <Anchor href="/credits" user={user} tenant={tenant}>
                              {intl.msg("credits")}
                            </Anchor>
                          </li>
                      }

                      {
                        user && user.isBasicOrAbove() && user.isReseller() &&
                          <>
                            <li className="menu-item">
                              <Anchor href="/refcodes" user={user} tenant={tenant}>
                                {intl.msg("refcodes")}
                              </Anchor>
                            </li>
                          </>
                      }

                      {/*{*/}
                      {/*  user && user.isBasicOrAbove() && (user.isSystem() || user.isReseller()) &&*/}
                      {/*    <>*/}
                      {/*      <li className="menu-item">*/}
                      {/*        <Anchor href="/income" user={user} tenant={tenant}>*/}
                      {/*          {intl.msg("revenue")}*/}
                      {/*        </Anchor>*/}
                      {/*      </li>*/}
                      {/*    </>*/}
                      {/*}*/}

                      {
                        user && user.isRestrictedOrAbove() && user.isSystem() &&
                        <>

                          <li className="menu-item">
                            <span style={{color: "var(--menu-border-color)"}}>|</span>
                          </li>

                          <li className="menu-item">
                            <Anchor href="/sys/regions" user={user} tenant={tenant}>
                              {intl.msg("regions")}
                            </Anchor>
                          </li>

                          <li className="menu-item">
                            <Anchor href="/sys/countries" user={user} tenant={tenant}>
                              {intl.msg("countries")}
                            </Anchor>
                          </li>

                          <li className="menu-item">
                            <Anchor href="/sys/locations" user={user} tenant={tenant}>
                              {intl.msg("locations")}
                            </Anchor>
                          </li>

                          <li className="menu-item">
                            <Anchor href="/sys/industries" user={user} tenant={tenant}>
                              {intl.msg("industries")}
                            </Anchor>
                          </li>

                          <li className="menu-item">
                            <Anchor href="/sys/currencies" user={user} tenant={tenant}>
                              {intl.msg("currencies")}
                            </Anchor>
                          </li>

                          <li className="menu-item">
                            <Anchor href="/sys/bankaccounts" user={user} tenant={tenant}>
                              {intl.msg("bank_accounts")}
                            </Anchor>
                          </li>

                          <li className="menu-item">
                            <Anchor href="/sys/props" user={user} tenant={tenant}>
                              {intl.msg("props")}
                            </Anchor>
                          </li>

                          <li className="menu-item">
                            <Anchor href="/sys/versions" user={user} tenant={tenant}>
                              {intl.msg("versions")}
                            </Anchor>
                          </li>

                        </>
                      }

                    </>
                }

              </ul>

              {/*{*/}
              {/*  user && user.isRestrictedOrAbove() && user.isSystem() &&*/}
              {/*    <li className="menu-item tools-item">*/}
              {/*      <a href="/sys" title={intl.msg("system_tooltip")}>*/}
              {/*        <div className="round-item">*/}
              {/*          <i className="fas fa-skull" style={{fontSize: "8pt"}}></i>*/}
              {/*        </div>*/}
              {/*      </a>*/}
              {/*    </li>*/}
              {/*}*/}

              <ul className="items">
                <li className="menu-item help-item">
                  <a href="#" className="dropdown-toggle dropdown-caret-off"  title={intl.msg("help_tooltip")} data-toggle="dropdown" data-offset="0,2" aria-haspopup="true" aria-expanded="false">
                    <div className="round-item">
                      ?
                    </div>
                  </a>
                  <div className="dropdown-menu" aria-labelledby="mni_help">
                    <a className="dropdown-item" href="/whatsnew">{intl.msg("whats_new")}</a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" href="https://help.solv.technology/category/using-solv" target="_blank">{intl.msg("hc_using_solv")}</a>
                    <a className="dropdown-item" href="https://help.solv.technology/category/faq" target="_blank">{intl.msg("hc_faq")}</a>
                    <a className="dropdown-item" href="https://help.solv.technology" target="_blank">{intl.msg("hc_help_center")}</a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item" href="#" data-toggle="modal" data-target="#dlg_about_solv">
                      <span className="mr-1">
                        {intl.msg("about_solv")}
                      </span>
                    </a>
                  </div>
                </li>

                <li className="menu-item user-item">
                  <a href="#" className="dropdown-toggle dropdown-caret-off"  title={user?.displayName} data-toggle="dropdown" data-offset="0,2" aria-haspopup="true" aria-expanded="false">
                    <div className="round-item">
                      {
                        user?.imageUrl ? (
                            <img src={user.imageUrl} style={{width: "18px", height: "18px", borderRadius: "50%"}}/>
                        ) : (
                            <i className="fas fa-user" style={{fontSize: "8pt"}}></i>
                        )
                      }
                    </div>
                  </a>
                  <div className="dropdown-menu" aria-labelledby="mni_user">
                    <DropdownMenuItem name="profile" href="/me"/>
                    {/*<a className="dropdown-item" href="/me">*/}
                    {/*  {intl.msg("profile")}*/}
                    {/*</a>*/}
                      {/*<span>{intl.msg("profile")}:</span>*/}
                      {/*<span className="ml-1 text-muted">{user?.displayName}</span></a>*/}
                    <DropdownMenuDivider/>
                    <DropdownMenuItem name="signout" onClick={handleSignout}/>
                    {/*<a className="dropdown-item" href="#" onClick={handleSignout}>*/}
                    {/*  {intl.msg("signout")}*/}
                    {/*</a>*/}
                  </div>
                </li>
              </ul>
            </div>
          </>
        }
      </nav>
      <div className="main-menu-push" style={{height: height}}></div>
      <AboutSolvDialog/>
    </>
  )
}
