import React, { useState, useEffect } from "react"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { AsyncPaginate } from "react-select-async-paginate"
import {default as Select} from "react-select";
import { v4 as uuidv4 } from 'uuid'
import ReCAPTCHA from "react-google-recaptcha"
import {useSolv} from "../components/SolvProvider"
import {useIntlEx} from "../components/IntlUtils"
import {Icon, MainContainer, useAlert} from "../components/FormComps"
import {PageDialogContainer, PageDialog, PageDialogMainPanel} from "../components/PageDialog"
import {GoogleButton} from "../components/GoogleButton"
import {LinkedInButton } from "../components/LinkedInButton"
import {isBlankString} from "../components/StringUtils"
import {validateEmailAddress} from "../components/ValidationUtils"
import {PageDialogHeader, PageDialogFooter, PageDialogSider} from "./PageDialogUtils";
import getLogger from "../components/Logging.js"
import "../App.css"
import "./PetSolv.css"

const log = getLogger("Signup")

function hashToStep(hash) {
  if (hash) {
    if (hash.startsWith("#")) {
      return hash.slice(1).toUpperCase()
    }
    else {
      return hash.toUpperCase()
    }
  }
  else {
    return "START"
  }
}

export default function Signup(props) {

  const {brandId, api, auth: {signup, generateMagicLink, registerDirectSignup}, env, setBusy, setFatal} = useSolv()
  const {intl} = useIntlEx()

  const [alert, setAlert] = useAlert()

  const params = useParams()
  const location = useLocation()
  const history = useHistory()

  const queryParams = new URLSearchParams(location.search)

  const [signupCode, setSignupCode] = useState(params.signupCode)
  const [magicCode, setMagicCode] = useState(params.magicCode)
  const [signupInfo, setSignupInfo] = useState(null)
  const [fullName, setFullName] = useState(queryParams.get(null))
  const [emailAddress, setEmailAddress] = useState(queryParams.get("emailAddress"))
  const [recaptchaResponse, setReCaptchaResponse] = useState(null)
  const [tenantName, setTenantName] = useState("PETSOLV" === brandId ? `UNIQ:${uuidv4()}` : null)
  const [region, setRegion] = useState(null)
  const [industry, setIndustry] = useState("PETSOLV" === brandId ? {value: env.DEFAULT_INDUSTRY_ID, label: "Pet"} : null)
  const [industries, setIndustries] = useState(null)
  const [step, setStep] = useState("START")
  const [regionSelectKey, setRegionSelectKey] = useState(null)

  useEffect(() => {
      setStep(hashToStep(location.hash))
  },[location])

  useEffect(() => {
    if (magicCode) {
      signupWithMagicCode(magicCode)
    }
  }, [magicCode])

  useEffect(() => {
    if (signupCode) {
      if ("START" === step) {
        log.debug("useEffect: signupCode=", signupCode)
        setBusy(intl.msg("loading"))
        api.getSignupInfo(signupCode)
          .then(({data}) => {
            log.debug("findSignupInfo: data=", data)
            setSignupInfo(data)
            if (data?.inviterTenant?.brandId !== brandId) {
              log.debug("findSignupInfo: error=INVALID_REFERRAL_CODE")
              handleError({code: "INVALID_REFERRAL_CODE"})
            }
          })
          .catch((error) => {
            handleError(error)
          })
          .finally(() => {
            setBusy(null)
          })
      }
    }
    else {
      setBusy(intl.msg("loading"))
      return api.listRepresentedIndustries("PETSOLV")
        .then(({data}) => {
          const l = data.map(d => {
            return ({label: intl.msg(`industry_${d.industryId.trim().toLowerCase()}`), value: d.industryId})
          })
          l.sort((a, b) => {
            if (a.value === "OTHER") {
              return 1
            }
            else if (b.value === "OTHER") {
              return -1
            }
            else {
              return a.label.localeCompare(b.label)
            }
          })
          setIndustries(l)
        })
        .catch((error) => {
          setFatal("OOPS")
        })
        .finally(() => {
          setBusy(null)
        })
    }
  }, [])

  function queryParamsToJson() {
    let result = {}
    for (const [key, value] of queryParams.entries()) {
      result[key] = value
    }
    return result
  }

  async function googleHandleSuccessfulLogin(response) {
    log.debug("googleHandleSuccessfulLogin: response=", response)
    try {
      try {
        setBusy(intl.msg("working"))
        let payload = {}
        if (signupInfo) {
          payload = {
            op: "SIGN_UP",
            idProvider: "GOOGLE",
            signupCode: signupCode,
            signupType: signupInfo.signupType,
            signupParams: queryParamsToJson(),
            tenantName: response.profileObj.emailAddress,
            profileObj: response.profileObj,
          }
        }
        else {
          payload = {
            op: "SIGN_UP",
            idProvider: "GOOGLE",
            signupCode: null,
            signupType: "DIRECT",
            signupParams: queryParamsToJson(),
            profileObj: response.profileObj,
          }
        }
        await signup(payload)
      }
      finally {
        setBusy(null)
      }
      window.location = "/onboarding"
    }
    catch (err) {
      log.debug("googleHandleSuccessfulLogin", err)
      handleError(err, "GOOGLE")
    }
  }

  function googleHandleFailedLogin(response) {
    setAlert({error: intl.msg("error_failed_google_signin"), response})
  }

  async function linkedInHandleSuccessfulLogin(response) {
    try {
      try {
        setBusy(intl.msg("working"))
        let payload = {}
        if (signupInfo) {
          payload = {
            op: "SIGN_UP",
            idProvider: "LINKEDIN",
            signupCode: signupCode,
            signupType: signupInfo.signupType,
            signupParams: queryParamsToJson(),
            code: response,
          }
        }
        else {
          payload = {
            op: "SIGN_UP",
            sigmupCode: null,
            signupType: "DIRECT",
            idProvider: "LINKEDIN",
            signupParams: queryParamsToJson(),
            code: response,
          }
        }
        await signup(payload)
      }
      finally {
        setBusy(null)
      }
      window.location = "/onboarding"
    } catch (err) {
      handleError(err, "LINKEDIN")
    }
  }

  function handleSignUpWithEmailClick() {
    setAlert(null)
    window.location = mkLink("/signup", "#email")  //`/signup/${signupCode}?${queryParams.toString()}#email`
  }

  function handleEmailAddressChange(e) {
    e.preventDefault()
    setEmailAddress(e.target.value)
  }

  function handleFullNameChange(e) {
    e.preventDefault()
    setFullName(e.target.value)
  }

  async function handleEmailContinueClick() {
    try {
      try {
        setBusy(intl.msg("working"))
        if (isBlankString(fullName)) {
          setAlert({error: intl.msg("error_invalid_input_full_name")})
        }
        else if (isBlankString(emailAddress) || !validateEmailAddress(emailAddress)) {
          setAlert({error: intl.msg("error_invalid_input_email_address")})
        }
        else if (!recaptchaResponse) {
          setAlert({error: intl.msg("error_invalid_input_recaptcha")})
        }
        else {
          let payload = {}
          if (signupInfo) {
            payload = {
              op: "SIGN_UP",
              signupCode: signupCode,
              signupType: signupInfo.signupType,
              signupParams: queryParamsToJson(),
              fullName: fullName,
              emailAddress: emailAddress,
              recaptcha: recaptchaResponse,
            }
          }
          else {
            payload = {
              op: "SIGN_UP",
              signupType: "DIRECT",
              fullName: fullName,
              emailAddress: emailAddress,
              recaptcha: recaptchaResponse,
            }
          }
          await generateMagicLink(payload)

          setAlert(null)
          queryParams.set("emailAddress", emailAddress)

          window.location = mkLink("/signup", "#check_email") //`/signup/${signupCode}?${queryParams.toString()}#check_email`
        }
      }
      finally {
        setBusy(null)
      }
    }
    catch (err) {
      log.debug("handleEmailContinueClick: err=", err)
      handleError(err)
    }
  }

  async function signupWithMagicCode(magicCode) {
    log.debug("signupWithMagicCode: magicCode=", magicCode)
    let signInData
    try {
      try {
        setBusy(intl.msg("working"))
        const payload = {
          op: "SIGN_UP",
          idProvider: "MAGICLINK",
          code: magicCode
        }
        setAlert(null)
        await signup(payload)
      }
      finally {
        setBusy(null)
      }
      window.location ="/onboarding"
    }
    catch (err) {
      handleError(err)
    }
  }

  function handleResendVerificationClick() {
    setAlert(null)
    window.location = mkLink("/signup", "#email") //`/signup/${signupCode}#email`
  }

  function searchRepresentedRegions(search, loadedOptions, additional) {
    return api.listRepresentedRegions(industry.value, search, additional?.cursor)
      .then(({data, nextCursor}) => {
        log.debug(">>>Z: data=", data)
        let opt = data.map(c => {
          return ({...c, value: c.regionId, label: c.regionName})
        });
        let res = {
          options: opt,
        }
        if (nextCursor) {
          res = {
            ...res,
            hasMore: true,
            additional: {
              cursor: nextCursor
            }
          }
        }
        log.debug("searchRegions: res=", res)
        return res
      })
  }

  function handleRegionChange(v) {
    setRegion(v)
  }

  function handleTenantNameChange(e) {
    setTenantName(e.target.value)
  }

  async function handleDirectSignupContinueClick() {

    if (isBlankString(tenantName)) {
      setAlert({error: intl.msg("error_invalid_input_tenant_name")})
    }
    else if (!region) {
      setAlert({error: intl.msg("error_invalid_input_region")})
    }
    else if (!recaptchaResponse) {
      setAlert({error: intl.msg("error_invalid_input_recaptcha")})
    }
    else {

      const payload = {
        brandId: brandId,
        regionId: region.value,
        industryId: ("PETSOLV" === brandId ? env.DEFAULT_INDUSTRY_ID : industry.value),
        tenantName: ("PETSOLV" === brandId? `UNIQ:${uuidv4()}` : tenantName),
        recaptcha: recaptchaResponse
      }

      setBusy(intl.msg("saving"))
      try {
        const {data} = await registerDirectSignup(payload)
        log.debug("handleDirectSignupContinueClick: data=", data)
        // setAlert(null)
        window.location = `/signup/${data.directSignupRequestId}`
      }
      catch (error) {
        handleError(error)
      }
      finally {
        setBusy(null)
      }
    }

  }

  function handleIndustryChange(v) {
    setIndustry(v)
    setRegionSelectKey(uuidv4().replace(/[\-]+/g,''))
  }

  function handleError(error) {
    log.debug("handleError: error=", error)
    if (error && error.code) {
      log.debug("handleError: code=", error.code)
      const code = error.code
      switch (code) {
        case "UNKNOWN_SIGNUP_CODE":
        case "INVALID_SIGNUP_CODE":
        case "INVALID_REFERRAL_CODE":
        case "ILLEGAL_REFERRAL":
          setAlert({error: intl.msg("signup_error_invalid_signup_code")})
          break
        case "UNKNOWN_INVITER":
        case "INVALID_INVITER":
          setAlert({error: intl.msg("signup_error_invalid_inviter")})
          break
        case "INVALID_INVITEE":
          setAlert({error: intl.msg("signup_error_invalid_invitee")})
          break
        case "DUPLICATE_KEY":
        case "DUPLICATE_INVITEE_USER":
          setAlert({error: intl.msg("signup_error_duplicate_user", {emailAddress: error.details?.emailAddress || ""})})
          break
        case "DUPLICATE_INVITEE_TENANT":
          setAlert({error: intl.msg("signup_error_duplicate_tenant", {tenantName: error.details?.tenantName || ""})})
          break
        case "UNAUTHORIZED":
        case "NOT_FOUND":
          setAlert({error: intl.msg("error_unknown_user")})
          break
        case "BANNED_INVITER":
        case "BANNED_USER":
        case "BANNED_REMOTE_ADDRESS":
          setAlert({error: intl.msg("error_banned_user")})
          break
        case "BANNED_EMAIL_ADDRESS":
          setAlert({error: intl.msg("error_banned_email_address")})
          break
        case "INVALID_EMAIL_ADDRESS":
          setAlert({error: intl.msg("error_invalid_email_address")})
          break
        case "INVALID_RECAPTCHA":
          setAlert({error: intl.msg("error_invalid_recaptcha")})
          break
        case "INVALID_MAGIC_LINK":
          setAlert({error: intl.msg("error_invalid_magic_link")})
          break
        case "DUPLICATE_MAGIC_LINK":
          setAlert({error: intl.msg("error_duplicate_magic_link")})
          break
        case "ONBOARDING":
          window.location = "/onboarding"
          break
        case "UNAVAILABLE":
          window.location = "/unavailable"
          break
        default:
          setAlert({error: intl.msg("error_failed")})
      }
    }
    else {
      setAlert({error: intl.msg("error_failed")})
    }
  }

  function mkLink(prefix, hash) {
    return `${prefix || "/signup"}/${signupCode}?${queryParams.toString()}${hash || ""}`
  }

  return (
    <MainContainer>
      <PageDialogContainer>
        <PageDialog header={PageDialogHeader} footer={PageDialogFooter} size="md" align="center">
            {
              signupCode ? (
                signupInfo ? (
                  ("EMAIL" === step) ? (
                    <>
                      <PageDialogSider/>
                      <PageDialogMainPanel title={intl.msg("signup_with_email_title")} alert={alert}>
                        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                          <p className="mt-3">
                            {intl.msg("signup_with_email_enter_info")}
                          </p>
                          <input className="form-control" key="inp_fullName" id="inp_fullName" name="fullName" style={{width: "300px", padding: "20px", color: "var(--form-control-text-color)", backgroundColor: "var(--form-control-background-color)", border: "1px solid var(--form-control-border-color)"}} placeHolder={intl.msg("your_full_name_placeholder")} value={fullName} onChange={handleFullNameChange}/>
                          <input className="form-control mt-3" key="inp_emailAddress" id="inp_emailAddress" name="emailAddress" style={{width: "300px", padding: "20px", color: "var(--form-control-text-color)", backgroundColor: "var(--form-control-background-color)", border: "1px solid var(--form-control-border-color)"}} placeHolder={intl.msg("your_email_address_placeholder")} value={emailAddress} onChange={handleEmailAddressChange}/>
                          <div id="fg_captcha" className="mt-3">
                            <ReCAPTCHA
                                sitekey={`${env.GOOGLE_RECAPTCHA_SECRET}`}
                                onChange={(e) => setReCaptchaResponse(e)}
                                theme={window.getComputedStyle(document.documentElement).getPropertyValue("--theme")}/>
                          </div>
                          <button className="btn btn-primary mt-3" style={{minWidth: "100px", padding: "8px", marginTop: "10px"}} onClick={handleEmailContinueClick}>
                            {intl.msg("continue")}
                          </button>
                          <div className="mt-3">
                            {intl.msg("signup_use_another_means", {a: chunks => <a className="text-link" href={mkLink("/signup")}>{chunks}</a>})}
                          </div>
                        </div>
                      </PageDialogMainPanel>
                    </>

                  ) : ("CHECK_EMAIL" === step) ? (
                    <>
                      <PageDialogMainPanel title={intl.msg("signup_with_email_check_your_email_title")} alert={alert}>
                        <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                          <p className="mt-3">
                            {intl.msg("signup_with_email_check_your_email_text", {emailAddress: emailAddress, b: chunks => <strong>{chunks}</strong>, em: chunks => <em>{chunks}</em>, p: chunks  => <p className="mt-3">{chunks}</p>})}
                          </p>
                          <button type="button" className="btn btn-primary mt-3" onClick={handleResendVerificationClick}>{intl.msg("resend")}</button>
                        </div>
                      </PageDialogMainPanel>
                    </>

                  ) : (
                    <>
                      <PageDialogSider/>
                      <PageDialogMainPanel title={intl.msg(signupInfo ? `signup_${signupInfo.signupType.toLowerCase()}_title` : `signup_direct_title`)} alert={alert}>
                        <p className="mt-3">
                          {
                            signupInfo ? (
                                intl.msg(`signup_${signupInfo.signupType.toLowerCase()}_text`, {
                                  inviterUserName: signupInfo.inviterUser?.displayName,
                                  inviterTenantName: signupInfo.inviterTenant?.tenantName,
                                  inviteeTenantName: signupInfo.inviterTenant?.tenantName,
                                  inviteeEmailAddress: signupInfo?.inviteeUser?.emailAddress,
                                  referralCode: signupInfo.signupCode?.toUpperCase(),
                                  brandId: brandId
                                })
                            ) : (
                                <></>
                            )
                          }
                        </p>
                        <div>
                          <GoogleButton
                              onSuccess={googleHandleSuccessfulLogin}z
                              onFailure={googleHandleFailedLogin}
                              disabled={false}
                              buttonText={intl.msg("signup_with_google")}/>
                        </div>
                        <div style={{marginTop: "6px"}}>
                          <LinkedInButton
                              onSuccess={linkedInHandleSuccessfulLogin}
                              buttonText={intl.msg("signup_with_linkedin")}/>
                        </div>
                        <div style={{marginTop: "6px", flexDirection: "column", alignItems: "center", }}>
                          <button className="btn-signin btn-signin-email" type="button" onClick={handleSignUpWithEmailClick}>
                            <div className="btn-signin-icon">
                              <Icon name="envelope2"/>
                            </div>
                            <span className="btn-signin-text">{intl.msg("signup_with_email")}</span>
                          </button>
                        </div>
                        <div className="mt-4" style={{fontSize: "8pt"}}>
                          {intl.msg("signup_by_clicking_signup", {a1: chunks => <a href="https://sassets.solv.technology/legal/terms.html" target="_blank">{chunks}</a>, a2: chunks => <a href="https://sassets.solv.technology/legal/privacy.html" target="_blank">{chunks}</a>})}
                        </div>
                        <div className="mt-4" style={{borderTop: "1px solid var(--form-divider-color)", paddingTop: "20px"}}>
                          {intl.msg("signup_already_have_an_account")}
                        </div>
                      </PageDialogMainPanel>
                    </>
                  )
                ) : (
                  <></>
                )
              ) : (
                <>
                  <PageDialogSider/>
                  <PageDialogMainPanel title={intl.msg("signup_direct_init_title", {brand: brandId})} alert={alert}>

                    <p className="mt-3 mb-0">
                      {intl.msg("signup_direct_init_text", {brand: brandId})}
                    </p>

                    <div className="row d-flex flex-column justify-content-center align-items-center mt-4">

                      {
                        "PETSOLV" !== brandId &&
                          <>
                            <div className="" style={{width: "300px"}}>
                              <input className="form-control" key="inp_tenantName" id="inp_tenantName" style={{width: "100%", padding: "20px", color: "var(--form-control-text-color)", backgroundColor: "var(--form-control-background-color)", border: "1px solid var(--form-control-border-color)"}} placeHolder={intl.msg("signup_account_name_placeholder")} value={fullName} onChange={handleTenantNameChange}/>
                            </div>

                            <div className="mt-2" style={{width: "300px"}}>
                              <Select
                                  id="inp_industry"
                                  className="react-select"
                                  classNamePrefix="react-select"
                                  cacheOptions
                                  value={industry}
                                  options={industries}
                                  onChange={handleIndustryChange}
                                  placeholder={intl.msg("signup_industry_placeholder")}
                                  isDisabled={false}
                              />
                            </div>
                          </>
                      }

                      {
                        industry &&
                          <div className="mt-2" style={{width: "300px"}}>
                            <AsyncPaginate
                              key={regionSelectKey}
                              id="inp_regions"
                              className="react-select"
                              classNamePrefix="react-select"
                              cacheOptions
                              value={region}
                              placeholder={intl.msg("petsolv_signup_region_placeholder")}
                              debounceTimeout={800}
                              defaultMenuIsOpen={false}
                              defaultOptions={true}
                              menuPosition={"fixed"}
                              loadOptions={searchRepresentedRegions}
                              onChange={handleRegionChange}
                              isDisabled={false}/>
                          </div>
                      }

                      <div id="fg_captcha" className="mt-2">
                        <ReCAPTCHA
                          sitekey={`${env.GOOGLE_RECAPTCHA_SECRET}`}
                          onChange={(e) => setReCaptchaResponse(e)}
                          theme={window.getComputedStyle(document.documentElement).getPropertyValue("--theme")}/>
                      </div>

                      <button className="btn btn-primary mt-3" onClick={handleDirectSignupContinueClick}>{intl.msg("continue")}</button>
                    </div>

                    <div className="mt-4" style={{fontSize: "8pt"}}>
                      {intl.msg("signup_by_clicking_continue", {a1: chunks => <a href="https://sassets.solv.technology/legal/terms.html" target="_blank">{chunks}</a>, a2: chunks => <a href="https://sassets.solv.technology/legal/privacy.html" target="_blank">{chunks}</a>})}
                    </div>
                    <div className="mt-4" style={{borderTop: "1px solid var(--form-divider-color)", paddingTop: "20px"}}>
                      {intl.msg("signup_already_have_an_account")}
                    </div>
                  </PageDialogMainPanel>
                </>
              )
            }
        </PageDialog>
      </PageDialogContainer>
    </MainContainer>
  )
}